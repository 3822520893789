import { Box } from '@sparky';
import { styled } from '@sparky/stitches';

export const SizedBox = styled(Box, {
  width: '64px',
  height: '64px',

  variants: {
    backgroundColor: {
      backgroundDark: {
        backgroundColor: '$backgroundDark',
      },
      feedbackError: {
        backgroundColor: '$feedbackError',
      },
      feedbackSuccess: {
        backgroundColor: '$feedbackSuccess',
      },
      feedbackWarning: {
        backgroundColor: '$feedbackWarning',
      },
    },
  },
});
