import React, { FC, PropsWithChildren } from 'react';

import { SizedBox } from '@custom-components/CircledNumber';
import { Stretch, Stack, Text } from '@sparky';
import { BoxProps, FeedbackColors } from '@sparky/types';

interface Props extends PropsWithChildren {
  backgroundColor?:
    | Extract<BoxProps['backgroundColor'], 'backgroundDark'>
    | Extract<keyof FeedbackColors, 'feedbackSuccess' | 'feedbackWarning' | 'feedbackError'>;
}

export const CircledNumber: FC<Props> = ({ backgroundColor, children }) => (
  <SizedBox borderRadius="round" backgroundColor={backgroundColor || 'backgroundDark'}>
    <Stretch>
      <Stack alignY="center">
        <Text size="BodyXL" weight="bold" color="textInverted" align="center">
          {children}
        </Text>
      </Stack>
    </Stretch>
  </SizedBox>
);
