import React from 'react';

import RichText from '@components/RichText/RichText';
import { CircledNumber } from '@components/ui/CircledNumber/CircledNumber';
import { useFormatter } from '@i18n';
import { useLinkComponent } from '@link';
import { Placeholder, mapImage, wrap } from '@sitecore/common';
import { ContentCardRendering } from '@sitecore/types/manual/ContentCard';
import {
  Badge,
  Box,
  ButtonLink,
  Card,
  Grid,
  Heading,
  Image,
  Stack,
  Stretch,
  Text,
  TextLink,
  VisuallyHidden,
} from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { useI18nTranslation } from '@sparky/providers';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

interface Props {
  variant?: 'default' | 'article';
}

const ContentCard: React.FC<ContentCardRendering & Props> = ({ fields, params, variant = 'default' }) => {
  const Link = useLinkComponent();
  const isDesktop = useMediaQuery('md');
  const step = useI18nTranslation('step');
  const { format } = useFormatter();

  const { title, content, badgeText, image, buttonLink, cardNumber, link } = fields;
  // Have to check for "1", as Sitecore's default behaviour is returning this as a string "1" or "0" instead of a boolean
  // Todo: Update this check when the response is updated to a boolean in Sitecore.
  const isElevated = params?.setElevation === '1';
  const isCompactOnMobile = params?.mobileVariant === 'compact';
  const isCompactOnDesktop = params?.desktopVariant === 'compact';
  const isCompactCard = (isDesktop && isCompactOnDesktop) || (!isDesktop && isCompactOnMobile);

  const hasBackgroundColor = Boolean(params?.backgroundColor);
  const headingLevel = params?.headingLevel || 'h3';

  const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) => {
    return condition ? wrapper(children) : children;
  };

  const imageProps = mapImage(image);
  const hasImage = !!imageProps.src;

  return (
    <Stretch>
      <ConditionalWrapper
        condition={isElevated}
        wrapper={children => (
          <Stretch>
            <Card overflow="hidden">
              <Box backgroundColor={hasBackgroundColor ? params.backgroundColor : 'backgroundPrimary'}>
                <ConditionalWrapper condition={isCompactCard} wrapper={children => <Box padding="6">{children}</Box>}>
                  {children}
                </ConditionalWrapper>
              </Box>
            </Card>
          </Stretch>
        )}>
        <Stretch>
          <Stack
            direction={isCompactCard ? 'row' : 'column'}
            gap={{ initial: isCompactCard ? '3' : '0', md: isCompactCard ? '6' : '0' }}>
            {imageProps.src && (
              <Box
                paddingX={!isCompactCard && isElevated && variant === 'article' ? '6' : '0'}
                paddingTop={!isCompactCard && isElevated && variant === 'article' ? '6' : '0'}>
                <Grid gridTemplateColumns="40px auto 1fr" gridTemplateRows="20px auto 1fr">
                  <Grid.Item gridColumn="1 / -1" gridRow="1 / -1">
                    <Image
                      alt={imageProps.alt}
                      width={{
                        initial: isCompactOnMobile ? '80px' : '100%',
                        md: isCompactOnDesktop ? '160px' : '100%',
                      }}
                      height={{
                        initial: isCompactOnMobile ? '80px' : 'auto',
                        md: isCompactOnDesktop ? '160px' : 'auto',
                      }}
                      src={imageProps.src}
                      srcSet={imageProps.srcSet ?? ''}
                      sizes={{
                        initial: isCompactOnMobile ? '80px' : '100%',
                        md: isCompactOnDesktop ? '160px' : '100%',
                      }}
                      objectFit={params?.objectFit ?? 'cover'}
                      aspectRatio={variant === 'article' ? '1/1' : '16/9'}
                    />
                  </Grid.Item>
                  {cardNumber.value && !isCompactCard && (
                    <Grid.Item gridColumn="2 / 2" gridRow="2 / 2">
                      <CircledNumber>{wrap(cardNumber)}</CircledNumber>
                    </Grid.Item>
                  )}
                </Grid>
              </Box>
            )}
            <Stack.Item grow>
              <Stretch>
                <Box
                  paddingX={isElevated && !isCompactCard ? '6' : '0'}
                  paddingTop={(isElevated || hasImage) && !isCompactCard ? '6' : '0'}
                  paddingBottom={isElevated && !isCompactCard ? '6' : '0'}>
                  <Stretch>
                    <Stack gap={isCompactCard ? '2' : '6'}>
                      <Stack.Item>
                        <Stack gap={isCompactCard ? '1' : '2'}>
                          <Heading as={headingLevel} size={{ initial: 'XS', md: 'S' }}>
                            {cardNumber.value && (
                              <VisuallyHidden>{format(step, { step: cardNumber.value })}: </VisuallyHidden>
                            )}
                            {wrap(title)}
                          </Heading>
                          {badgeText?.editable || badgeText?.value ? (
                            <Stack.Item>
                              <Badge size="M">{wrap(badgeText)}</Badge>
                            </Stack.Item>
                          ) : null}
                        </Stack>
                      </Stack.Item>

                      <Stack.Item grow>
                        <Text size={{ initial: 'BodyM', md: 'BodyL' }}>
                          {wrap(content, <RichText html={content.value} />)}
                        </Text>
                      </Stack.Item>

                      <Placeholder name="jss-content-card-main" />

                      {buttonLink?.value.href && buttonLink.value.text ? (
                        <Stack.Item>
                          <Stretch width={{ initial: true, md: false }}>
                            <Link
                              editable={buttonLink.editable}
                              linkType={buttonLink.value.linktype}
                              linkValue={buttonLink.value}>
                              <ButtonLink target={buttonLink.value.target}>{buttonLink.value.text}</ButtonLink>
                            </Link>
                          </Stretch>
                        </Stack.Item>
                      ) : null}

                      {link?.value.href && link.value.text ? (
                        <Stack.Item>
                          <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
                            <TextLink
                              {...(hasBackgroundColor
                                ? { color: 'textOnBackgroundVarOne', emphasis: 'low' }
                                : { emphasis: 'high' })}
                              target={link.value.target}>
                              {link.value.text}
                            </TextLink>
                          </Link>
                        </Stack.Item>
                      ) : null}
                    </Stack>
                  </Stretch>
                </Box>
              </Stretch>
            </Stack.Item>
          </Stack>
        </Stretch>
      </ConditionalWrapper>
    </Stretch>
  );
};

export default ContentCard;
